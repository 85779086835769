//
//
//
//
//
//
//
//
//

import HeaderComponent from 'src/modules/components/layout/Header'
import ContextComponent from './Context'

import UserMixin from 'src/modules/mixins/user'

export default {
  components: {
    HeaderComponent,
    ContextComponent
  },
  mixins: [UserMixin],
  created() {
    window.location.href = 'workbench'
  }
}
