var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "entry-container" }, [
    _c("div", { staticClass: "entry-welcome" }, [
      _c(
        "div",
        { staticClass: "entry-modules ticket-chart" },
        [_c("ticket", { staticClass: "entry-module-item" })],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "entry-modules sub" },
        [
          _c("histogram", {
            staticClass: "entry-module-item",
            attrs: {
              width: 650,
              query: _vm.callcenterQuery,
              title: "400二线和MC售前工单处理量"
            },
            on: {
              click: function($event) {
                return _vm.jumpTicket($event, _vm.callcenterQuery.channel)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "entry-modules sub" },
        [
          _c("histogram", {
            staticClass: "entry-module-item",
            attrs: {
              width: 650,
              query: _vm.mcQuery,
              title: "MC售后工单处理量"
            },
            on: {
              click: function($event) {
                return _vm.jumpTicket($event, _vm.mcQuery.channel)
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "entry-modules" },
      [
        _c("histogram", {
          staticClass: "entry-module-item",
          attrs: {
            query: _vm.feedbackQuery,
            title: "不满意，投诉，产品咨询处理工单量"
          },
          on: {
            click: function($event) {
              return _vm.jumpTicket($event, _vm.feedbackQuery.channel)
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "entry-modules" },
      [_c("service-stress", { staticClass: "entry-module-item" })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }