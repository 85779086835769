//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from './api';
import moment from 'moment';
import { timeCategory } from './utils';
import Highcharts from 'src/libs/highcharts.js';
import CurrentTabLoopEventMixin from 'src/modules/mixins/page-event/current-tab-loop-event';

export default {
  mixins: [CurrentTabLoopEventMixin],
  data () {
    return {
      delay: 309000,
      time: 309000,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      },
      chart: {},
      query: {
        date: new Date()
      },
      datas: {}
    }
  },
  watch: {
    query: {
      deep: true,
      handler (val) {
        this.fetchData(val).then(data => {
          if (data && data.code === 0) {
            const datas = data.data;
            this.updateChart(datas);
          }
        });
      }
    }
  },
  mounted () {
    this.initChart();
    this.chart.showLoading('加载中...');
    this.fetchData(this.query).then(data => {
      if (data && data.code === 0) {
        const datas = data.data;
        this.chart.hideLoading();
        this.updateChart(datas);
      }
    });
  },
  methods: {
    loopEvent () {
      this.fetchData(this.query).then(data => {
        if (data && data.code === 0) {
          const datas = data.data;
          this.updateChart(datas);
        }
      });
    },
    async fetchData (query = {}) {
      const _query = { ...query };
      _query.date = _query.date ? moment(_query.date).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
      const { data } = await api.getTickets({
        query: _query
      });
      return data;
    },
    initChart () {
      const options = this.getChartOption();
      this.chart = Highcharts.chart(this.$refs.chart, options);
    },
    updateChart (datas) {
      const series = this.getSeries(datas);
      this.chart.update({
        series
      });
    },
    getSeries (datas) {
      return datas.map(item => {
        return {
          name: item.name,
          data: item.data.map(_item => {
            return _item === 0 ? { y: _item, color: '#ff0000' } : _item;
          })
        }
      });
    },
    getChartOption () {
      return {
        chart: {
          height: 340,
          type: 'line'
        },
        title: {
          text: '当日工单实时数据'
        },
        colors: ['#555', '#2aadee', '#f6bb42'],
        xAxis: {
          categories: timeCategory(),
          tickmarkPlacement: 'on'
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          },
          minTickInterval: 1
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          borderWidth: 0,
          floating: true
        },
        plotOptions: {
          series: {
            animation: false
          },
          line: {
            marker: {
              enabled: false
            }
          }
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.x + '</b><br/>新增单量: ' + this.y + ' 单.';
          }
        },
        series: [{
          name: '400工单量',
          data: []
        }, {
          name: 'MC售前工单量',
          data: []
        }, {
          name: 'MC售后工单量',
          data: []
        }]
      }
    }
  }
}
