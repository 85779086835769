//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenMixin from 'src/modules/mixins/page-event/open';
import ServiceStress from 'src/modules/components/charts/ServiceStress';
import Ticket from 'src/modules/components/charts/Ticket';
import Histogram from 'src/modules/components/charts/Histogram';
import queryString from 'query-string';

import homeImage from 'modules/img/TSS-home.png';

export default {
  components: {
    Ticket,
    Histogram,
    ServiceStress
  },
  mixins: [OpenMixin],
  data () {
    return {
      callcenterQuery: {
        channel: [2, 3],
        queue: [3, 7, 74, 86, 87]
      },
      mcQuery: {
        channel: [3],
        queue: [1, 4, 15, 22, 28, 29, 81]
      },
      feedbackQuery: {
        channel: [12, 13]
      },
      background: {
        background: `url(${homeImage}) no-repeat top`
      }
    }
  },
  methods: {
    jumpTicket ({ operator, status }, channel) {
      this.open(`/ticket/list/?${queryString.stringify({
        current_operator: operator,
        status,
        service_channel: channel
      }, {
        arrayFormat: 'bracket'
      })}`)
    }
  }
}
