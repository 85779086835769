"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _setInterval2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-interval"));

var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));

var _default = {
  data: function data() {
    return {
      // 第一次请求延时时长
      delay: 1000,
      // 记录interval的id, 用于清除轮询计时器
      interval: 0,
      // 轮询请求的时间间隔
      time: 10000
    };
  },
  methods: {
    // 执行通知轮询请求
    startReq: function startReq() {
      var _this = this;

      (0, _setTimeout2["default"])(function () {
        _this.loopEvent();
      }, this.delay);
      this.interval = (0, _setInterval2["default"])(function () {
        _this.loopEvent();
      }, this.time);
    },
    // 覆盖此方法实现轮询事件
    loopEvent: function loopEvent() {
      console.log('当前页面加载了轮询，并且未设置要轮询执行的loopEvent方法');
    },
    endReq: function endReq() {
      clearInterval(this.interval);
    }
  }
};
exports["default"] = _default;