//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from './api';
import moment from 'moment';
import { timeCategory } from './utils';
import Highcharts from 'src/libs/highcharts.js';
import CurrentTabLoopEventMixin from 'src/modules/mixins/page-event/current-tab-loop-event';

export default {
  mixins: [CurrentTabLoopEventMixin],
  data () {
    return {
      chart: {},
      delay: 300000,
      time: 300000,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      },
      query: {
        date: new Date(),
        duty: '全部'
      },
      datas: {},
      dutyOptions: [
        '全部',
        '域名队列',
        '基础队列',
        '技术队列',
        '视频云队列',
        '数据库队列',
        '需求队列',
        '应用队列',
        '中间件队列',
        '二线队列'
      ]
    }
  },
  watch: {
    query: {
      deep: true,
      handler (val) {
        this.fetchData(val).then(data => {
          if (data && data.code === 0) {
            const datas = data.data;
            this.updateChart(datas);
          }
        });
      }
    }
  },
  mounted () {
    this.initChart();
    this.chart.showLoading('加载中...');
    this.fetchData(this.query).then(data => {
      if (data && data.code === 0) {
        const datas = data.data;
        this.chart.hideLoading();
        this.updateChart(datas);
      }
    });
  },
  methods: {
    loopEvent () {
      this.fetchData(this.query).then(data => {
        if (data && data.code === 0) {
          const datas = data.data;
          this.updateChart(datas);
        }
      });
    },
    async fetchData (query = {}) {
      const _query = { ...query };
      _query.date = _query.date ? moment(_query.date).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
      const { data } = await api.getServiceStress({
        query: _query
      });
      return data;
    },
    // 绘制工单队列压力表格
    initChart () {
      const options = this.getChartOption();
      this.chart = Highcharts.chart(this.$refs.chart, options);
    },
    updateChart (datas) {
      const data = this.setChart(datas);
      this.chart.update({
        series: [{
          name: '上周同比压力值',
          data: data.lastWeekData || [],
          today: datas.today,
          lastWeek: datas.lastWeek
        }, {
          name: '当日压力值',
          data: data.todayData || [],
          today: datas.today,
          lastWeek: datas.lastWeek
        }]
      })
    },
    // 加载图表数据
    setChart (datas) {
      const data = datas;
      return {
        lastWeekData: this.initLastWeek(data.lastWeek),
        todayData: this.initToday(data.today, data.date)
      }
    },
    initLastWeek (lastWeek) {
      let lastWeekData = [];
      for (let i = 0; i < 96; i++) {
        if (lastWeek.staff_count[i] === 0) {
          lastWeekData.push({
            y: lastWeek.service_stress[i],
            color: '#ff0000'
          });
        } else {
          lastWeekData.push(lastWeek.service_stress[i]);
        }
      }
      return lastWeekData;
    },
    initToday (today, date) {
      let todayData = [];
      for (var i = 0; i < 96; i++) {
        if (today.staff_count[i] === 0) {
          todayData.push({
            y: today.service_stress[i],
            color: '#ff0000'
          });
        } else {
          todayData.push(today.service_stress[i]);
        }
      }
      return todayData;
    },
    // 获取工单队列压力表格配置项
    getChartOption () {
      return {
        chart: {
          height: 340,
          type: 'line'
        },
        title: {
          text: '售后工单队列压力'
        },
        colors: ['#555', '#2aadee'],
        xAxis: {
          categories: timeCategory(),
          tickmarkPlacement: 'on'
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          },
          minTickInterval: 1
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          borderWidth: 0,
          floating: true
        },
        plotOptions: {
          series: {
            animation: false
          },
          line: {
            marker: {
              enabled: false
            }
          }
        },
        tooltip: {
          formatter: function () {
            const { lastWeek, today } = this.series.userOptions;
            if (lastWeek && today) {
              if (this.series.name === '上周同比压力值') {
                return '<b>' + this.x + '</b><br/>工单量: ' + lastWeek.ticket_count[this.point.x] + '<br/>人力: ' + lastWeek.staff_count[this.point.x] + '<br/>压力: ' + lastWeek.service_stress[this.point.x];
              } else {
                return '<b>' + this.x + '</b><br/>工单量: ' + today.ticket_count[this.point.x] + '<br/>人力: ' + today.staff_count[this.point.x] + '<br/>压力: ' + today.service_stress[this.point.x];
              }
            }
          }
        },
        series: [{
          name: '上周同比压力值',
          data: [],
          lastWeek: {},
          today: {}
        }, {
          name: '当日压力值',
          data: [],
          lastWeek: {},
          today: {}
        }]
      }
    }
  }
}
