"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context; _forEachInstanceProperty(_context = ownKeys(Object(source), true)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getTickets = function getTickets(_ref) {
  var _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;
  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: '/statistics/api/overview/tickets'
  }, config));
};

var getOperators = function getOperators(_ref2) {
  var _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;
  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: '/statistics/api/overview/operators'
  }, config));
};

var getServiceStress = function getServiceStress(_ref3) {
  var _ref3$query = _ref3.query,
      query = _ref3$query === void 0 ? {} : _ref3$query,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;
  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: '/statistics/api/service-stress'
  }, config));
};

var _default = {
  getTickets: getTickets,
  getOperators: getOperators,
  getServiceStress: getServiceStress
};
exports["default"] = _default;