"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.timeCategory = void 0;

var _moment = _interopRequireDefault(require("moment"));

var INTERVAL_MINUTE = 15; // 一天的时间间隔区间

var timeCategory = function timeCategory() {
  var start = (0, _moment["default"])().startOf('day');
  var end = (0, _moment["default"])().endOf('day');
  var results = [];

  while (start.isSameOrBefore(end)) {
    results.push(start.format('HH:mm'));
    start.add(INTERVAL_MINUTE, 'minutes');
  }

  return results;
};

exports.timeCategory = timeCategory;