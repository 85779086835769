"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var _default = [{
  key: 1,
  id: [1, 6],
  numKey: 'todo',
  prop: 'status',
  value: '待客服处理'
}, {
  key: 2,
  id: [2, 8],
  numKey: 'doing',
  prop: 'status',
  value: '处理中'
}, {
  key: 3,
  id: [7, 9],
  numKey: 'yunwei',
  value: '运维处理中'
}];
exports["default"] = _default;