"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _vue = _interopRequireDefault(require("src/libs/vue"));

var _App = _interopRequireDefault(require("./App.vue"));

require("./index.less");

/* eslint-disable no-new */
new _vue["default"]({
  el: '#app',
  render: function render(h) {
    return h(_App["default"]);
  }
});