//
//
//
//
//
//

import api from './api';
import Highcharts from 'src/libs/highcharts.js';
import CurrentTabLoopEventMixin from 'src/modules/mixins/page-event/current-tab-loop-event';
import status from 'src/modules/enums/Workbench/todo-status';

export default {
  mixins: [CurrentTabLoopEventMixin],
  props: ['query', 'width', 'title'],
  data () {
    return {
      delay: 305000,
      time: 305000,
      chart: {}
    }
  },
  computed: {
    todoStatus () {
      const { id } = status[0];
      return {
        id: '待处理',
        value: [...id]
      }
    },
    doingStatus () {
      const { id: staffId } = status[1];
      const { id: operationId } = status[2];
      return {
        id: '处理中',
        value: [...staffId, ...operationId]
      }
    }
  },
  mounted () {
    this.initChart();
    this.chart.showLoading('加载中...');
    this.fetchData(this.query).then(data => {
      if (data && data.code === 0) {
        const datas = data.data;
        this.chart.hideLoading();
        this.updateChart(datas);
      }
    });
  },
  methods: {
    loopEvent () {
      this.fetchData(this.query).then(data => {
        if (data && data.code === 0) {
          const datas = data.data;
          this.updateChart(datas);
        }
      });
    },
    async fetchData (query = {}) {
      const _query = { ...query };
      const { data } = await api.getOperators({
        query: _query
      });
      return data;
    },
    initChart () {
      const options = this.getChartOption();
      this.chart = Highcharts.chart(this.$refs.chart, options);
    },
    updateChart (datas) {
      const data = datas;
      const categories = data.map(item => {
        return typeof item.user_id === 'number' ? item.user_name : item.user_id;
      });
      const doing = data.map(item => {
        return {
          user: item,
          y: item.doing
        }
      });
      const todo = data.map(item => {
        return {
          user: item,
          y: item.todo
        }
      });
      this.chart.update({
        xAxis: {
          categories
        },
        series: [{
          name: this.todoStatus.id,
          maxPointWidth: 30,
          data: todo
        }, {
          name: this.doingStatus.id,
          maxPointWidth: 30,
          data: doing
        }]
      });
    },
    getChartOption () {
      const self = this;
      return {
        chart: {
          animation: false,
          height: 340,
          width: self.width,
          type: 'column'
        },
        title: {
          text: self.title
        },
        colors: ['#e9573f', '#2aadee'],
        xAxis: {
          categories: []
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          }
        },
        tooltip: {
          formatter: function () {
            return this.x + '<b>' + this.series.name + '：' + this.y + ' 单</b>';
          }
        },
        plotOptions: {
          series: {
            animation: false
          },
          column: {
            marker: {
              enabled: false
            }
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          borderWidth: 0,
          floating: true
        },
        series: [{
          name: this.todoStatus.id,
          maxPointWidth: 30,
          data: [],
          cursor: 'pointer',
          events: {
            click: (e) => {
              this.$emit('click', {
                operator: e.point.user.user_id,
                status: this.todoStatus.value
              });
            }
          }
        }, {
          name: this.doingStatus.id,
          maxPointWidth: 30,
          data: [],
          cursor: 'pointer',
          events: {
            click: (e) => {
              this.$emit('click', {
                operator: e.point.user.user_id,
                status: this.doingStatus.value
              });
            }
          }
        }]
      }
    }
  }
}
