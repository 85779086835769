var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "el-container",
        { attrs: { "flex-box": "1" } },
        [
          _c(
            "el-header",
            { attrs: { height: "auto" } },
            [_c("header-component", { attrs: { user: _vm.user } })],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }