"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _loopEvent = _interopRequireDefault(require("./loop-event"));

var _default = {
  mixins: [_loopEvent["default"]],
  created: function created() {
    this.regEvent();
  },
  methods: {
    // 注册窗口监听事件
    regEvent: function regEvent() {
      var _this = this;

      this.startReq();
      /**
       * 修复Firefox环境无法监听window.blur
       */

      window.addEventListener('visibilitychange', function () {
        if (document.hidden) {
          _this.endReq();
        }
      });
      window.addEventListener('blur', this.endReq);
      window.addEventListener('focus', this.startReq);
    },
    unRegEvent: function unRegEvent() {
      this.endReq();
      window.removeEventListener('blur', this.endReq);
      window.removeEventListener('focus', this.startReq);
    }
  }
};
exports["default"] = _default;